import {
  Center,
  Title,
  Text,
  Flex,
  Box,
  BackgroundImage,
  Stack,
  Image,
  Button,
  List,
  ThemeIcon,
} from "@mantine/core";

import { Carousel } from "@mantine/carousel";

import { useMediaQuery } from "@mantine/hooks";

import { Checks } from "tabler-icons-react";

import Contact from "./ContactForm";

function Events() {
  return (
    <>
      <Box mt={20}>
        <Center>
          <Title align="center" weight={600} order={3} mb={20}>
            Upcoming Events: mis het niet!
          </Title>
        </Center>
        <Center>
          <iframe
            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FLearnov8%2Fposts%2Fpfbid0soSqGUwxbVjr1SQZW3Ws9owVck5J6ANPRFvUk5PGo6geQZ6PJ31kdrHoUoACia8Zl&show_text=true&width=500"
            width="500"
            height="861"
            style={{ border: "none", overflow: "hidden" }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          />
        </Center>
      </Box>
      <Contact />
    </>
  );
}

export default Events;

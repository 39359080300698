import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { MantineProvider, MantineThemeOverride } from "@mantine/core";

import App from "./App";
import { Home } from "./Home";
import Service from "./Service";
import CareerJournalPage from "./CareerJournal";
import EventsPage from "./Events";
import ContactPage from "./ContactPage";
import BrandBrunchPage from "./BrandBrunchPage";
import BrandBrunchProgrammaPage from "./BrandBrunchProgrammaPage";
import MasterClassPage from "./MasterClassPage";
import reportWebVitals from "./reportWebVitals";

const myTheme = {
  colorScheme: "light",
  primaryColor: "orange",
  defaultRadius: 0,
  fontFamily: "Montserrat, sans-serif",
  headings: {
    // properties for all headings
    fontWeight: 300,

    //properties for individual headings, all of them are optional
    sizes: {
      // ...up to h6
      // h6: { fontWeight: 900 },
    },
  },
  colors: {
    "bright-pink": [
      "#7b0144",
      "#7b0144",
      "#7b0144",
      "#7b0144",
      "#7b0144",
      "#7b0144",
      "#7b0144",
      "#7b0144",
      "#7b0144",
      "#7b0144",
    ],
  },
};

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MantineProvider theme={myTheme} withGlobalStyles withNormalizeCSS>
      <Router>
        <Routes>
          {/* <Route path="*" element={<NotFound />} /> */}
          <Route path="/" element={<App />}>
            <Route path="/" element={<Home />} />
            <Route path="/services/:slug" element={<Service />} />
            <Route path="/careerjournal" element={<CareerJournalPage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/brandbrunch" element={<BrandBrunchPage />} />
            <Route
              path="/brandbrunch-programma"
              element={<BrandBrunchProgrammaPage />}
            />
            <Route path="/contact" element={<ContactPage />} />

            <Route path="/masterclass" element={<MasterClassPage />} />
          </Route>
          {/* <Route path="/login" element={<Login />} /> */}
        </Routes>
        <ScrollToTop />
      </Router>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {
  Center,
  Title,
  Text,
  Box,
  Button,
  List,
  ThemeIcon,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { Checks } from "tabler-icons-react";
import Contact from "./ContactForm";

function BrandBrunchHero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <video
        style={{ width: "100%", height: "auto", display: "block" }}
        autoPlay
        playsInline
        loop
        muted
      >
        <source src="/brandbrunch_video.mp4" type="video/mp4" />
        Your browser does not support video.
      </video>
    </Box>
  );
}

function BrandBrunchProgram() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title align="center" weight={600} order={3}>
        Brand Brunch{" "}
        <Text span color="bright-pink" weight="700">
          Programma
        </Text>
      </Title>
      <Text mt={20}>
        <strong>Je bent slim, werkt hard en hebt ambities.</strong> Maar
        eerlijk? <strong>Je voelt dat je onbenut potentieel hebt.</strong>
      </Text>
      <Text mt={10}>
        <strong>Je bent slim, werkt keihard en weet dat je méér kunt.</strong>{" "}
        Maar toch… die promotie blijft uit. Je wordt over het hoofd gezien in
        meetings. En als er een kans vrijkomt, gaat die naar iemand die minder
        ervaren is, maar wél zichtbaar.
      </Text>
      <Text mt={10}>
        Ondertussen groeit de frustratie. Je ziet collega's wél hun carrière
        boosten, terwijl jij blijft wachten tot iemand je 'ontdekt'. Maar laten
        we eerlijk zijn:{" "}
        <strong>
          zichtbaarheid is geen kwestie van geluk. Het is een strategie.
        </strong>
      </Text>
      <Box
        sx={(theme) => ({
          borderRadius: "5px",
        })}
        c="black"
        w="100%"
        p="2em"
        bg="#FBF4EA"
        mt={20}
      >
        <List spacing="sm" size="md" icon="❌">
          <List.Item>
            Hoe zorg ik dat mensen mij zien als expert, leider of
            high-potential?
          </List.Item>
          <List.Item>
            Hoe bouw ik een sterk personal brand zonder me nep of overdreven te
            voelen?
          </List.Item>
          <List.Item>
            Hoe presenteer ik mezelf krachtig, zonder dat ik mezelf constant
            moet bewijzen?
          </List.Item>
        </List>
      </Box>
      <Text mt={20}>
        🔥{" "}
        <strong>Dit is waar het Brand Brunch Programma jou bij helpt.</strong>{" "}
        In drie maanden gaan we samen werken aan jouw zichtbaarheid, invloed en
        carrière-impact.{" "}
        <strong>
          Zodat je niet langer aan de zijlijn staat, maar regisseur wordt van je
          eigen succes.
        </strong>
      </Text>
      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Voor wie is dit?
      </Title>
      <List spacing="sm" mt={10} size="md" icon="✔">
        <List.Item>
          <strong> Voor de ambitieuze professional in loondienst</strong> die
          niet langer genoegen neemt met onzichtbaarheid en wíl groeien.
        </List.Item>
        <List.Item>
          <strong>Voor de female manager</strong> die klaar is om haar
          leiderschap zichtbaar te maken en meer impact te hebben.
        </List.Item>
        <List.Item>
          <strong>Voor de vrouw die next-level wil gaan</strong> in haar
          carrière, maar niet weet hoe ze dat strategisch aanpakt.
        </List.Item>
      </List>
      <Text mt={10} align="center">
        <cursive>
          Als een van deze omschrijvingen je raakt, is het tijd om in actie te
          komen.
        </cursive>
      </Text>
      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Wat krijg je?
      </Title>
      <Text mt={10}>
        🚀 Dit is geen standaard coachingtraject, maar een high-level, exclusief
        programma waarin je{" "}
        <strong>
          mentale, fysieke en online zichtbaarheid naar een hoger niveau wordt
          getild.
        </strong>
      </Text>
      <List spacing="sm" mt={10} size="md" icon="✅">
        <List.Item>
          <strong>Vier exclusieve 1-op-1 sessies (3 uur per keer)</strong> –
          online of fysiek, afgestemd op jouw tempo en behoeften. We werken{" "}
          <strong>hands-on</strong> aan jouw positionering en next career move.
        </List.Item>
        <List.Item>
          <strong>Een professionele fotoshoot</strong> – zodat je krachtige
          beelden hebt voor je website, LinkedIn en CV.
        </List.Item>
        <List.Item>
          <strong>Persoonlijke styling & powerlooks (5-8 outfits)</strong> –
          zodat jouw uitstraling direct zegt:{" "}
          <strong>'Ik ben er klaar voor!'</strong>
        </List.Item>
        <List.Item>
          <strong>Een out-of-your-comfort-zone activiteit</strong> – designed to
          challenge and elevate you! Don't let this scare you—I've got you!
        </List.Item>
        <List.Item>
          <strong>Een strategische personal branding roadmap</strong> – zodat je
          exact weet hoe je jezelf zichtbaar en onweerstaanbaar positioneert.
        </List.Item>
        <List.Item>
          <strong>Concrete opdrachten en feedback</strong> – geen loze adviezen,
          maar actiegerichte stappen die je direct gaat toepassen.
        </List.Item>
      </List>
      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Wat levert het je op?
      </Title>
      <List spacing="sm" mt={10} size="md" icon="💡">
        <List.Item>
          <strong>Je krijgt erkenning voor je werk en skills.</strong> Niet
          langer 'de onzichtbare harde werker', maar iemand waar mensen rekening
          mee houden.
        </List.Item>
        <List.Item>
          <strong>Je weet hoe je jezelf presenteert</strong> – zonder te
          twijfelen aan je eigen verhaal.
        </List.Item>
        <List.Item>
          <strong>Je spot én creëert nieuwe kansen</strong> die eerder aan je
          voorbijgingen.
        </List.Item>
        <List.Item>
          <strong>
            Je voelt je krachtiger, zelfverzekerder en meer in control over je
            carrière.
          </strong>
        </List.Item>
        <List.Item>
          <strong>Je hebt een plan.</strong> Je weet wat je volgende stap is en
          hoe je die zet.
        </List.Item>
        <List.Item>
          <strong>Je bouwt aan een personal brand</strong> dat je niet alleen
          nu, maar jarenlang helpt groeien.
        </List.Item>
      </List>
      <Text mt={20}>
        En misschien wel het belangrijkste?{" "}
        <strong>Je wordt weer enthousiast over je carrière.</strong> Je voelt
        passie, lef en energie om groots te denken en te doen. 🚀✨
      </Text>
      <Text mt={20}>
        <strong>Wat is de investering?</strong>
      </Text>
      <Text mt={10}>
        De investering in het Brand Brunch Programma is €2800,- exclusief btw.
        Dit is de prijs voor een compleet traject dat jouw carrière naar een
        hoger niveau tilt.
      </Text>
      <Text mt={10}>
        <strong>Betalen in Termijnen? Geen probleem! </strong>
      </Text>{" "}
      <Text mt={10}>
        Je kunt ook in 1 tot 4 termijnen betalen. Let op: vaak vergoed door je
        werkgever uit het opleidingsbudget.
      </Text>
      <Box
        sx={(theme) => ({
          borderRadius: "5px",
        })}
        bg="#7b0144"
        mt="2em"
        w="100%"
        c="white"
        p="2em"
      >
        <Title c="white" align="center" weight={600} order={4}>
          Is dit de investering waard?
        </Title>

        <Text mt={10} c="white">
          <strong>Laten we eerlijk zijn:</strong> hoeveel heeft het je al gekost
          om níet zichtbaar te zijn?
        </Text>

        <List spacing="sm" mt={10} size="md" icon="🚨">
          <List.Item>
            <Text c="white">
              <strong>Hoeveel kansen zijn er al aan je voorbijgegaan?</strong>
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>
                Hoe vaak heb je gewacht op erkenning die nooit kwam?
              </strong>
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>
                Hoeveel extra geld had je kunnen verdienen als je de juiste
                positie had gekregen?
              </strong>
            </Text>
          </List.Item>
        </List>

        <Text mt={20} c="white">
          💡{" "}
          <strong>
            De vrouwen die dit traject hebben gevolgd, verdienen hun investering
            dubbel en dwars terug.
          </strong>{" "}
          Omdat ze de juiste kansen krijgen. Omdat ze eindelijk de positie en
          het salaris hebben waar ze recht op hebben.
        </Text>
      </Box>
      <Text mt={20} align="center" weight={600}>
        Je kunt blijven hopen dat iemand je opmerkt… óf je kunt nu in actie
        komen.
      </Text>
      <Text mt={20} align="center" weight={600}>
        💥 Plan een kennismakingsgesprek en ontdek of dit traject bij je past!
      </Text>
      <Center>
        <Button
          sx={{
            "&:hover": {
              backgroundColor: "#7b0144",
            },
          }}
          bg="#fda701"
          mt="2em"
          component={NavLink}
          to="/contact"
          key="contact"
        >
          PLAN JE KENNISMAKINGSGESPREK
        </Button>
      </Center>
    </Box>
  );
}

function BrandBrunchProgramPage() {
  return (
    <>
      <BrandBrunchHero />
      <BrandBrunchProgram />
      <Contact />
    </>
  );
}

export default BrandBrunchProgramPage;

import { useRef, useEffect, useState } from "react";

import {
  Center,
  Title,
  Text,
  Flex,
  Box,
  BackgroundImage,
  Stack,
  Image,
  Button,
  Paper,
  SimpleGrid,
  UnstyledButton,
  Blockquote,
} from "@mantine/core";

import Autoplay from "embla-carousel-autoplay";

import Contact from "./ContactForm";

import { Carousel } from "@mantine/carousel";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

function Hero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box>
      <Image
        sx={(theme) => ({
          width: "100%",
          height: "auto",
        })}
        src="/hero2.jpg"
        radius="xs"
      ></Image>
    </Box>
  );
}

const InfiniteCarousel = () => {
  const isMobile = useMediaQuery("(max-width: 56.25em)");
  const logos = Array.from({ length: 10 }, (_, i) => i + 1);
  const allLogos = [...logos, ...logos];

  // Adjust animation duration based on screen size
  const animationDuration = isMobile ? "12s" : "20s";

  return (
    <Box py={30} bg="white" mx="auto" w="100%" sx={{ overflow: "hidden" }}>
      <Box sx={{ position: "relative", width: "100%" }}>
        <Box
          sx={(theme) => ({
            display: "flex",
            animation: `scroll ${animationDuration} linear infinite`,
            "&:hover": {
              animationPlayState: "paused",
            },
            "@keyframes scroll": {
              "0%": {
                transform: "translateX(0)",
              },
              "100%": {
                transform: "translateX(-50%)",
              },
            },
          })}
        >
          {allLogos.map((num, index) => (
            <Box
              key={`${num}-${index}`}
              px={16}
              sx={(theme) => ({
                flexShrink: 0,
                width: isMobile ? "30%" : "10%",
              })}
            >
              <Box>
                <Image
                  src={`/logos/logo${num}.png`}
                  alt={`Company logo ${num}`}
                  sx={(theme) => ({
                    img: {
                      height: "60px",
                      width: "auto",
                      margin: "0 auto",
                      objectFit: "contain",
                      filter: "grayscale(100%)",
                      opacity: 0.7,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        filter: "grayscale(0%)",
                        opacity: 1,
                      },
                    },
                  })}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

function IntroductionDutch() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  return (
    <Box id="about" mx="auto" p={20} w={matches ? "1000px" : "100%"}>
      <Stack>
        <Box
          sx={(theme) => ({
            background: "#7C0144",
            color: "white",
            textAlign: "center",
            padding: 5,
            borderRadius: "5px",
          })}
        >
          <Title>Get to know Tsnatee.</Title>
        </Box>

        <Text ff="Merriweather Sans" size="lg" weight={700}>
          Hi, mijn naam is Tsnatee,
        </Text>
        <Text>
          Ik ben een always-on & always-on-fire presentatrice, spreker, trainer,
          dagvoorzitter en consultant. Mijn missie? Jouw evenement of
          organisatie naar een hoger niveau tillen. Met mijn expertise in
          personal branding, loopbaanontwikkeling en DEI (diversiteit,
          gelijkheid en inclusie) breng ik niet alleen energie en enthousiasme
          mee, maar ook mijn jarenlange ervaring in podiumkunsten, tv, marketing
          en events. Samen maken we van jouw event een onvergetelijke ervaring!
        </Text>
        <Text size="md">
          Mijn passie komt voort uit één simpele gedachte: niemand hoeft te
          wachten op zijn kans om te shinen. Ik geloof dat je je eigen podium
          kunt creëren, en ik help je graag om dat te doen. Of het nu gaat om
          het herontdekken van je passie, in je kracht staan, of hernieuwde
          energie vinden voor je werk, ik sta naast je om die stappen te zetten.
        </Text>
        <Text size="md">
          In december 2023 lanceerde ik mijn eerste boek:{" "}
          <Text span italic>
            The Career Journal
          </Text>
          . Dit werkboek is jouw persoonlijke kompas om jezelf te ontwikkelen in
          je loopbaan. Want groei en zelfontplooiing zijn niet alleen
          belangrijk, ze horen ook leuk en inspirerend te zijn!
        </Text>
        <Text size="md">
          Met mij op jouw podium krijg je geen standaard ervaring. Ik zorg
          ervoor dat elk event hands-on, inspirerend en vol positieve energie
          is. Ik daag je uit, zet je aan het denken, en geef je vooral het
          gevoel dat jij gezien wordt, precies zoals je bent.
        </Text>

        <Title order={4} weight={700} mt={20}>
          3 Fun Facts over mij:
        </Title>

        <Text size="md">
          <Text span weight={700}>
            🌟 Mijn naam spreek je uit als "Snaa-Tee"
          </Text>{" "}
          Het betekent 'prachtig kostbaar souvenir' én 'kracht.' Aan het begin
          van mijn presentaties zeg ik altijd:{" "}
          <Text span italic>
            "Ik hoop dat jullie een stukje souvenir meenemen – met de kracht om
            voor jullie carrièredoelen en authentieke personal brand te gaan!"
          </Text>{" "}
          Thanks mom and dad voor deze unieke naam! 😄 (Ook al vinden veel
          mensen het lastig om uit te spreken!)
        </Text>

        <Text size="md">
          <Text span weight={700}>
            🌟 Je kunt me altijd wakker maken voor een theatervoorstelling of
            musical.
          </Text>{" "}
          Van jongs af aan combineer ik mijn liefde voor optreden met mijn
          passie om mensen te inspireren. Mijn meest recente musicals?{" "}
          <Text span italic>
            Chicago The Musical
          </Text>
          ,{" "}
          <Text span italic>
            A Chorus Line
          </Text>{" "}
          en{" "}
          <Text span italic>
            Cinderella The Musical
          </Text>
          , waarvoor ik zelfs een Broadway The Netherlands Award won. ✨
        </Text>

        <Text size="md">
          <Text span weight={700}>
            🌟 Ik ben een corporate girl at heart.
          </Text>{" "}
          Soms glippen er wat corporate termen uit mijn mond,{" "}
          <Text span italic>
            bear with me as I "navigate this challenge"
          </Text>{" "}
          (snap je 'm? 😉). Maar hé, het maakt mijn aanpak lekker strategisch én
          relatable!
        </Text>

        <Text size="md" mt={20} italic>
          Ben je klaar om samen impact te maken? Je kunt me boeken als spreker,
          trainer of dagvoorzitter. Let's make magic happen!
        </Text>
      </Stack>
    </Box>
  );
}

function Service({ image, link, text }) {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <UnstyledButton component={NavLink} to={link}>
      <Box
        mx="auto"
        sx={(theme) => ({
          background: "#7C0144",
          color: "white",
          textAlign: "center",
          width: matches ? "300px" : "100%",
          aspectRatio: "1/1",
          borderRadius: "5px",
        })}
      >
        <BackgroundImage src={image} radius="5px">
          <Box
            sx={(theme) => ({
              width: matches ? "300px" : "100%",
              aspectRatio: "1/1",
              display: "flex",
              paddingBottom: "25px",
              borderRadius: "5px",
            })}
          >
            <Button
              sx={(theme) => ({
                alignSelf: "flex-end",
              })}
              radius="5px"
              mx="auto"
              variant="default"
            >
              {text}
            </Button>
          </Box>
        </BackgroundImage>
      </Box>
    </UnstyledButton>
  );
}

function Services() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box id="services" pb={50} mx="auto" p={20} w="100%">
      <Stack>
        <Box
          w={matches ? "700px" : "100%"}
          mx="auto"
          mb={50}
          sx={(theme) => ({
            background: "#7C0144",
            color: "white",
            textAlign: "center",
            padding: 5,
            borderRadius: "5px",
          })}
        >
          <Title>Work with Tsnatee.</Title>
        </Box>

        <SimpleGrid
          cols={3}
          mx="auto"
          mb={50}
          w={matches ? "900px" : "100%"}
          spacing="xl"
          breakpoints={[
            { maxWidth: "lg", cols: 3, spacing: "md" },
            { maxWidth: "md", cols: 1, spacing: "md" },
          ]}
        >
          <Service
            image="/service1.jpg"
            text="Spreker"
            link="/services/speaker"
          />
          <Service
            image="/service2.jpg"
            text="Trainer"
            link="/services/trainer"
          />
          <Service
            image="/service3.jpg"
            text="Dagvoorzitter"
            link="/services/presenter"
          />
        </SimpleGrid>
      </Stack>
    </Box>
  );
}

function CareerJournal() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  return (
    <Box
      id="careerjournal"
      my={20}
      mx="auto"
      p={50}
      w={matches ? "900px" : "100%"}
      bg="#FBF4EA"
      sx={(theme) => ({
        borderRadius: "5px",
      })}
    >
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "sm", sm: "lg" }}
        justify={{ base: "space-between", sm: "center" }}
      >
        <Box w="300px" mx="auto">
          <Center>
            <Image src="/careerjournal.png" />
          </Center>
        </Box>
        <Box
          sx={(theme) => ({
            maxWidth: "400px",
          })}
          mx="auto"
        >
          <Stack align="center" gap="xl">
            <Title weight="600" order={2}>
              Career Journal
            </Title>
            <Text size="md" align="center">
              De Career Journal - is je ultieme gids, je doelenplanner, je
              werkboek voor loopbaanontwikkeling en je visionaire partner,
              allemaal in één. Met deze praktische journal breng je jouw
              grootste ambities tot leven door ze om te zetten in concrete
              stappen die je elke dag/week kunt plannen. Zo werk je actief aan
              het realiseren van je persoonlijke en professionele doelen! Van
              het proactiever leren netwerken, tot het beter kunnen promoten van
              jouw skills tot het realiseren van een gezonde work-life balance -
              deze journal ondersteunt je op elke stap van je reis naar succes.
            </Text>
            <Button
              bg="#fda715"
              mt="20px"
              w="150px"
              component={NavLink}
              to="/careerjournal"
            >
              Lees Meer
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
}

function Definition() {
  return (
    <Box
      pt={40}
      h={600}
      sx={(theme) => ({
        width: "100%",
        textAlign: "center",
        padding: 5,
      })}
    >
      <Box
        mx="auto"
        sx={(theme) => ({
          width: "700px",

          textAlign: "center",
          padding: 5,
        })}
      >
        <Paper bg="#FDA715" shadow="xl" radius="xs" p="xl" withBorder>
          <Box>
            <Text c="white" ff="Montserrat" size="4rem">
              Tsnatee
            </Text>
            <Text c="white">
              <Text component="span" fw={600}>
                Definition:
              </Text>{" "}
              Perseverance & Strength
            </Text>
            <Text c="white">
              <Text component="span" fw={600}>
                Pronounciation:
              </Text>{" "}
              {"{"}snaa-tay{"}"}
            </Text>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

const testimonials = [
  {
    text: "The facilitator is an energy bomb, an inspiring young woman that makes you wish this workshop was a few hours longer. Truly useful especially for a young professional who could overlook the importance of personal branding!",
    author: "Ellemijn - Marketing Risk & Compliance Lead - Exellys",
  },
  {
    text: "Great presentation. Nice energy. It was really a pleasure. I am very thankful for the energy Tsnatee shared with us and I am grateful that she shared her personal stories with us.",
    author: "Martina - Senior Advisor - Dell Technologies",
  },
  {
    text: "Tsnatee is great, enthusiastic, experienced, can provide information in an interesting way, nice and funny. Tsnatee gives a feeling you want to watch more of her videos, and listen to her advice.",
    author: "Zuzana - Program Manager - Dell Technologies",
  },
  {
    text: "If you're on the lookout for a genuine mix of learning and hands-on experience, along with the ability to apply what you learn right away, Tsnatee is your person. Tsnatee's workshops are more than just educational; they're brimming with enthusiasm, and the fun factor is through the roof!",
    author: "Femke - Talent Development & Account Manager - Exellys",
  },
];

function Testimonials() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  return (
    <Box id="about" mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Stack>
        <Box
          sx={(theme) => ({
            background: "#fda715",
            color: "white",
            textAlign: "center",
            padding: 5,
            borderRadius: "5px",
          })}
        >
          <Title>Testimonials</Title>
        </Box>

        <Box mt="20px" mx="auto" w="100%">
          <Carousel
            align="center"
            maw={600}
            height="auto"
            slideGap="lg"
            draggable={true}
            withIndicators
            loop
            withControls={false}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            styles={{
              indicator: {
                marginTop: "10px",
                background: "#fada01",
              },
            }}
          >
            {testimonials.map((testimonial, index) => (
              <Carousel.Slide key={index}>
                <Box
                  sx={(theme) => ({
                    padding: "10px",
                    fontStyle: "italic",
                    borderRadius: "5px",
                    color: "white",
                  })}
                >
                  <Blockquote
                    color="#7C0144"
                    radius="lg"
                    iconSize={100}
                    cite={testimonial.author}
                    mt="xl"
                  >
                    {testimonial.text}
                  </Blockquote>
                </Box>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Box>
      </Stack>
    </Box>
  );
}

function Home() {
  return (
    <>
      <Hero />
      <InfiniteCarousel />
      <IntroductionDutch />
      <Services />
      <Testimonials />
      <CareerJournal />
      <Contact />
    </>
  );
}

export { Home };

import React, { useRef, useState } from "react";
import {
  Center,
  Title,
  Text,
  Box,
  Button,
  List,
  ThemeIcon,
  Blockquote,
  SegmentedControl,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { Checks } from "tabler-icons-react";
import Contact from "./ContactForm";

// Hero component remains the same
function BrandBrunchHero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <video
        style={{ width: "100%", height: "auto", display: "block" }}
        autoPlay
        playsInline
        loop
        muted
      >
        <source src="/brandbrunch_video.mp4" type="video/mp4" />
        Your browser does not support video.
      </video>
    </Box>
  );
}

function BrandBrunchDutch1() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title align="center" weight={600} order={3}>
        ✨ Need a career boost? Boek jouw Brand Brunch! ✨
      </Title>

      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Herken jij dit?
      </Title>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          "Ik weet echt niet wat ik moet posten op LinkedIn..."
        </List.Item>
        <List.Item>
          "Ik vind het moeilijk om de vraag 'Wie ben je?' te beantwoorden!"
        </List.Item>
        <List.Item>
          "Niemand ziet wat ik echt waard ben, zelfs mijn leidinggevende
          niet..."
        </List.Item>
        <List.Item>
          "Ik wil zichtbaar zijn voor de juiste mensen, maar weet niet hoe..."
        </List.Item>
      </List>

      <Text mt={20}>
        Als je jezelf herkent in één of meerdere van deze punten, dan is de{" "}
        <strong>Brand Brunch</strong> precies wat je nodig hebt! De{" "}
        <strong>Brand Brunch</strong> is jouw kans om eindelijk duidelijkheid te
        krijgen over je branding en carrière. Het is een{" "}
        <strong>één-op-één sessie</strong> van <strong>3 uur</strong>, waarin we
        jouw carrière, persoonlijke merk en zichtbaarheid naar een hoger niveau
        tillen. In <strong>3 uur</strong>? Jazeker! Aan het eind heb jij de
        carrièreboost waar je naar snakt!
      </Text>

      <Box
        sx={(theme) => ({
          borderRadius: "5px",
        })}
        c="black"
        w="100%"
        p="2em"
        bg="#FBF4EA"
        mt={30}
      >
        <Title c="black" align="center" weight={600} order={4}>
          Wat maakt de Brand Brunch speciaal?
        </Title>
        <Text mt={10}>
          De <strong>Brand Brunch</strong> is uniek omdat we niet alleen praten,
          we <strong>DOEN</strong>! Hier is waarom deze sessie jou zo zal
          helpen:
        </Text>

        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="black" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="black">
              <strong>Hands-on werken:</strong> Je gaat direct aan de slag en
              creëert content die bij je past en die je kunt inzetten.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>We craften jouw verhaal:</strong> Aan het eind heb jij een
              krachtige pitch, of het nu voor een sollicitatiegesprek is, 1-op-1
              met je manager, of iemand die je ontmoet op een event.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>LinkedIn Upgrade:</strong> Samen zullen we, op basis van
              jouw ambities en kwaliteiten, jouw LinkedIn-profiel gelijk
              bijwerken.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>Real-time begeleiding:</strong> Je krijgt hands-on hulp om
              je brand met zelfvertrouwen online en offline te presenteren.
            </Text>
          </List.Item>
        </List>
      </Box>

      <Title c="black" align="center" weight={600} order={4} mt={30}>
        Wat kun je nog meer verwachten?
      </Title>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          <strong>30-minuten prep-call:</strong> We bespreken jouw huidige
          carrière, carrièrevraagstukken en je branddoelen voor de sessie.
        </List.Item>
        <List.Item>
          <strong>Werkboek:</strong> Een week voor de sessie ontvang je je eigen
          werkboek, zodat je alvast aan de slag kunt met jouw vraagstukken.
        </List.Item>
        <List.Item>
          <strong>Personal Branding sessie van 3 uur:</strong> We werken samen
          aan strategische oefeningen, zodat jij helder hebt waar je heen wilt
          en hoe je dat bereikt.
        </List.Item>
        <List.Item>
          <strong>360° Online Brand Analyse:</strong> Volledige analyse van jouw
          online aanwezigheid (LinkedIn, content, foto's) en hoe je dit kunt
          verbeteren.
        </List.Item>
      </List>
    </Box>
  );
}

// Continue with BrandBrunchDutch2, BrandBrunchDutch3, BrandBrunchEnglish1, BrandBrunchEnglish2, BrandBrunchEnglish3...

function BrandBrunchDutch2() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box
      sx={(theme) => ({
        borderRadius: "5px",
      })}
      bg="#7b0144"
      mt="2em"
      w="100%"
      c="white"
    >
      <Box w={matches ? "700px" : "100%"} p="2em" mx="auto">
        <Title align="center" weight={600} order={4}>
          Wat krijg je erbij?
        </Title>

        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="white" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="white">
              <strong>Gratis Brunch:</strong> Terwijl we werken aan je branding,
              geniet je van een heerlijke maaltijd.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Extra Follow-Up Gesprek van 30 minuten:</strong> Extra
              begeleiding om je succes op de lange termijn te verzekeren.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Career Journal Cadeau:</strong> Een speciaal cadeau om je
              progressie bij te houden en je toekomst te plannen.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Investering: €899 excl. btw</strong> (en vaak vergoed door
              je werkgever uit het opleidingsbudget). Betaalmogelijkheden: 1-2
              termijnen.
            </Text>
          </List.Item>
        </List>
      </Box>
    </Box>
  );
}

function BrandBrunchDutch3() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Hoe boek je jouw Brand Brunch?
      </Title>

      <Text mt={10}>
        <strong>Stap 1: Plan een video-chat met mij!</strong>
      </Text>
      <Text mt={5}>
        We leren elkaar kennen! Jij deelt je doelen en wensen, en samen maken we
        een plan op maat voor jouw Brand Brunch.
      </Text>

      <Text mt={10}>
        <strong>Stap 2: Plan jouw Brand Brunch</strong>
      </Text>
      <Text mt={5}>
        Tijdens onze video-chat plannen we jouw sessie in, zodat je meteen kunt
        beginnen met het versterken van je brand!
      </Text>

      <Center>
        <Button
          mt="1em"
          component={NavLink}
          to="/contact"
          key="contact"
          sx={{
            "&:hover": {
              backgroundColor: "#7b0144",
            },
          }}
          bg="#fda701"
        >
          Plan je video-chat nu!
        </Button>
      </Center>
    </Box>
  );
}

function BrandBrunchEnglish1() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title align="center" weight={600} order={3}>
        ✨ Need a career boost? Book your Brand Brunch! ✨
      </Title>

      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Do you recognize yourself in this?
      </Title>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>"I really don't know what to post on LinkedIn…"</List.Item>
        <List.Item>"I struggle when someone asks me, 'Who are you?'"</List.Item>
        <List.Item>"No one sees my true worth, not even my manager…"</List.Item>
        <List.Item>
          "I want to be visible to the right people, but I don't know how…"
        </List.Item>
      </List>

      <Text mt={20}>
        If any of these sound familiar, the <strong>Brand Brunch</strong> is
        exactly what you need! The <strong>Brand Brunch</strong> is your chance
        to finally get clarity on your personal brand and career. It's a{" "}
        <strong>one-on-one session</strong> of <strong>3 hours</strong>, where
        we'll take your career, personal brand, and visibility to the next
        level. In <strong>3 hours</strong>? Yep! By the end, you'll have the
        career boost you've been craving!
      </Text>

      <Box
        sx={(theme) => ({
          borderRadius: "5px",
        })}
        c="black"
        w="100%"
        p="2em"
        bg="#FBF4EA"
        mt={30}
      >
        <Title c="black" align="center" weight={600} order={4}>
          What makes the Brand Brunch so special?
        </Title>
        <Text mt={10}>
          The <strong>Brand Brunch</strong> is unique because we don't just
          talk, we <strong>DO</strong>! Here's why this session will help you:
        </Text>

        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="black" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="black">
              <strong>Hands-on work:</strong> You'll dive in and create content
              that fits you and that you can actually use.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>We craft your story:</strong> By the end, you'll have a
              powerful pitch – whether it's for a job interview, a one-on-one
              with your manager, or someone you meet at an event.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>LinkedIn Upgrade:</strong> Together, we'll update your
              LinkedIn profile based on your ambitions and strengths.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>Real-time guidance:</strong> You'll get hands-on help to
              present your brand with confidence, both online and offline.
            </Text>
          </List.Item>
        </List>
      </Box>

      <Title c="black" align="center" weight={600} order={4} mt={30}>
        What else can you expect?
      </Title>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          <strong>30-minute prep-call:</strong> We'll discuss your current
          career, career challenges, and your brand goals before the session.
        </List.Item>
        <List.Item>
          <strong>Workbook:</strong> A week before your session, you'll receive
          your own workbook to start working on your challenges.
        </List.Item>
        <List.Item>
          <strong>3-hour Personal Branding session:</strong> We'll work together
          on strategic exercises so you know exactly where you're headed and how
          to get there.
        </List.Item>
        <List.Item>
          <strong>360° Online Brand Analysis:</strong> A full analysis of your
          online presence (LinkedIn, content, photos) and how to improve it.
        </List.Item>
      </List>
    </Box>
  );
}

function BrandBrunchEnglish2() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box
      sx={(theme) => ({
        borderRadius: "5px",
      })}
      bg="#7b0144"
      mt="2em"
      w="100%"
      c="white"
    >
      <Box w={matches ? "700px" : "100%"} p="2em" mx="auto">
        <Title align="center" weight={600} order={4}>
          What do you get with it?
        </Title>

        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="white" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="white">
              <strong>Free Brunch:</strong> While we work on your branding,
              you'll enjoy a delicious meal.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Extra 30-minute Follow-Up Call:</strong> Additional
              guidance to ensure long-term success.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Career Journal Gift:</strong> A special gift to help you
              track your progress and plan your future.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Investment: €899 excl. VAT</strong> (often reimbursed by
              your employer through their training budget). Payment options: 1-2
              installments.
            </Text>
          </List.Item>
        </List>
      </Box>
    </Box>
  );
}

function BrandBrunchEnglish3() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        How to book your Brand Brunch?
      </Title>

      <Text mt={10}>
        <strong>Step 1: Schedule a video chat with me!</strong>
      </Text>
      <Text mt={5}>
        Let's get to know each other! You'll share your goals and wishes, and
        together we'll create a tailored plan for your Brand Brunch.
      </Text>

      <Text mt={10}>
        <strong>Step 2: Schedule your Brand Brunch</strong>
      </Text>
      <Text mt={5}>
        During our video chat, we'll schedule your session, so you can start
        strengthening your brand right away!
      </Text>

      <Center>
        <Button
          mt="1em"
          component={NavLink}
          to="/contact"
          key="contact"
          sx={{
            "&:hover": {
              backgroundColor: "#7b0144",
            },
          }}
          bg="#fda701"
        >
          Schedule your video chat now!
        </Button>
      </Center>
    </Box>
  );
}

function BrandBrunchContent({ language }) {
  return language == "dutch" ? (
    <>
      <BrandBrunchDutch1 />
      <BrandBrunchDutch2 />
      <BrandBrunchDutch3 />
    </>
  ) : (
    <>
      <BrandBrunchEnglish1 />
      <BrandBrunchEnglish2 />
      <BrandBrunchEnglish3 />
    </>
  );
}

function BrandBrunchPage() {
  const [language, setLanguage] = useState("dutch");

  return (
    <>
      <BrandBrunchHero />
      <Center>
        <Box w="14em" mt="2em">
          <SegmentedControl
            value={language}
            onChange={(value) => setLanguage(value)}
            fullWidth
            withItemsBorders={false}
            radius="xl"
            data={[
              { label: "🇳🇱 Nederlands", value: "dutch" },
              { label: "🇬🇧 English", value: "english" },
            ]}
            size="xs"
          />
        </Box>
      </Center>
      <BrandBrunchContent language={language} />
      <Contact />
    </>
  );
}

export default BrandBrunchPage;
